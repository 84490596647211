import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js';

function sliderContent () {
  const slider = document.getElementById('jsSliderContent'),
        options = {
          container: '#jsSliderContent',
          mode: 'carousel',
          items: 1,
          controls: true,
          controlsPosition: 'bottom',
          nav: false,
          responsive: {
            576: {
              items: 2
            },
            992: {
              items: 3
            }
          },
          speed: 400
        };

  if (slider) {
    const sliderContent = tns(options);
    return sliderContent;
  }
}

sliderContent();
