import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js';

function headerSlider () {
  const slider = document.getElementById('jsHeaderSlider'),
        options = {
          container: '#jsHeaderSlider',
          items: 1,
          controls: false,
          mode: 'gallery',
          navPosition: 'bottom',
          speed: 400
        };

  if (slider) {
    const headerSlider = tns(options);
    return headerSlider;
  }
}

function showHeaderVideo () {
  const btn = document.getElementById('jsHeaderVideoButton'),
        video = document.getElementById('jsHeaderVideo'),
        thumb = document.getElementById('jsHeaderVideoThumb');

  if (btn) {
    btn.addEventListener('click', () => {
      video.classList.remove('d-none');
      thumb.classList.add('d-none');
    });
  }
}

headerSlider();
showHeaderVideo();
