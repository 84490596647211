function showFilters () {
  const btn = document.getElementById('jsFiltersButton'),
        filters = document.getElementById('jsFilters'),
        filterLists = document.getElementById('jsFiltersLists');

  if (btn) {
    btn.addEventListener('click', () => {
      if (btn.getAttribute('aria-expanded') === 'false') {
        filterLists.classList.remove('d-none');
        setTimeout(() => {
          filterLists.classList.add('fade-in');
        }, 1);
        setTimeout(() => {
          filterLists.classList.remove('fade-in');
          filterLists.classList.add('filters-active');
        }, 210);
        filters.classList.add('is-open');
        btn.setAttribute('aria-expanded', 'true');
      } else {
        filterLists.classList.add('fade-out');
        setTimeout(() => {
          filterLists.classList.remove('filters-active');
        }, 1);
        setTimeout(() => {
          filterLists.classList.remove('fade-out');
          filterLists.classList.add('d-none');
        }, 210);
        filters.classList.remove('is-open');
        btn.setAttribute('aria-expanded', 'false');
      }
    });
  }
}

function getSubmitUrlFilter() {
  const checked = document.querySelectorAll('input[type="checkbox"]:checked');
  
  //get selected teams
  const checkedTeams = Array.from(checked).filter(x => x.name === 'team');
  let urlTeams = '';
  if (checkedTeams.length) {
      for (let idx=0; idx < checkedTeams.length; idx++) 
      {
          if (urlTeams === '') {
              urlTeams = 'team=' + checkedTeams[idx].value.toLowerCase();
          }
          else {
              urlTeams += ',' + checkedTeams[idx].value.toLowerCase();
          }
      }
  }
  
  //get selected companies
  const checkedCompanies = Array.from(checked).filter(x => x.name === 'company');
  let urlCompanies = '';
  if (checkedCompanies.length) {
      for (let idx=0; idx < checkedCompanies.length; idx++) 
      {
          if (urlCompanies === '') {
              urlCompanies = 'company=' + checkedCompanies[idx].value.toLowerCase();
          }
          else {
              urlCompanies += ',' + checkedCompanies[idx].value.toLowerCase();
          }
      }
  }
  
  //get selected countries
  const checkedCountries = Array.from(checked).filter(x => x.name === 'country');
  let urlCountries = '';
  if (checkedCountries.length) {
      for (let idx=0; idx < checkedCountries.length; idx++) 
      {
          if (urlCountries === '') {
              urlCountries = 'country=' + checkedCountries[idx].value.toLowerCase();
          }
          else {
              urlCountries += ',' + checkedCountries[idx].value.toLowerCase();
          }
      }
  }
  
  let finalUrl = '';
  if (urlTeams !== '') 
  {
      finalUrl = urlTeams;
  }
  if (urlCompanies !== '') 
  {
      if (finalUrl === '') 
      {
          finalUrl = urlCompanies;
      }
      else 
      {
          finalUrl = finalUrl + '&' + urlCompanies;
      }
  }
  if (urlCountries !== '') 
  {
      if (finalUrl === '') 
      {
          finalUrl = urlCountries;
      }
      else 
      {
          finalUrl = finalUrl + '&' + urlCountries; 
      }
  }
  
  return finalUrl;
}

function getSearchUrl(searchValue, filterUrl) {
  let searchUrl = '';
  if (searchValue !== '') 
  {
      searchUrl = '?q=' + searchValue;
  }
  if (filterUrl !== '') 
  {
      if (searchUrl === '')
      {
          searchUrl = '?' + filterUrl;
      }
      else
      {
          searchUrl = searchUrl + '&' + filterUrl;
      }
  }
  return searchUrl;
}

function addSearchFilterSubmit () {
  const checkboxes = document.getElementsByClassName('js-filter-checkbox');
  const submit = document.getElementById('js-search-vacancy-button');
  const form = document.getElementById('js-search-vacancy-form');
  const searchBox = document.getElementById('filterSearch');

  for(let i = 0; i < checkboxes.length; i++) {
      const item = checkboxes[i];
      item.addEventListener("click", function () {
          const filterUrl = getSubmitUrlFilter();
          document.location = window.location.origin + window.location.pathname + getSearchUrl(searchBox.value,filterUrl);
      });
  }  
}

function addSearchSubmit () {
const submit = document.getElementById('js-search-vacancy-button');
const form = document.getElementById('js-search-vacancy-form');
  const searchBox = document.getElementById('filterSearch');
if (submit && form) {
    submit.addEventListener("click", function () {
          const filterUrl = getSubmitUrlFilter();
          document.location = window.location.origin + window.location.pathname + getSearchUrl(searchBox.value,filterUrl);
     });
}
}

function addSearchReset () {
const submit = document.getElementById('js-reset-vacancy-button');
const form = document.getElementById('js-search-vacancy-form');
if (submit && form) {
    submit.addEventListener("click", function () {
          document.location = window.location.origin + window.location.pathname;
     });
}
}

if (document.getElementById('js-apply-button') !== undefined && document.getElementById('js-apply-button') !== null) {
  document.getElementById('js-applyform').hidden = true;
  document.getElementById('js-apply-button').addEventListener('click', (e) => {
    e.preventDefault();
    showApplicationForm();
  });
  if (document.getElementsByClassName('formsapplyforposition')[0] !== undefined) {
    const vacancyTitle = document.getElementById('js-vacancytitle').value;
    document.getElementsByClassName('formsapplyforposition')[0].querySelectorAll('input')[0].readOnly = true;
    document.getElementsByClassName('formsapplyforposition')[0].querySelectorAll('input')[0].value = vacancyTitle;
  }
}

function showApplicationForm() {
  if (document.getElementById('js-apply') !== undefined) {
    document.getElementById('js-apply').hidden = true;
    if (document.getElementsByClassName('contact__img')[0] !== undefined) {
      document.getElementsByClassName('contact__img')[0].hidden = true;
    }
    document.getElementById('js-applyform').hidden = false;
    document.getElementsByClassName('hiddenemailto')[0].querySelectorAll('input')[0].value = document.getElementById('js-manageremail').value;
  }
}

if (document.getElementsByClassName('applyingforposition') !== null) {
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('vacancy')) {
    const vacancyTitle = decodeURIComponent(urlParams.get('vacancy'));
    document.getElementsByClassName('applyingforposition')[0].querySelectorAll('input')[0].readOnly = true;
    document.getElementsByClassName('applyingforposition')[0].querySelectorAll('input')[0].value = vacancyTitle;
  }
}

const fileUploads = document.getElementsByClassName('fileupload');

if (fileUploads.length > 0) {
  var array = [].slice.call(fileUploads);
  array.forEach(element => {
    element.querySelectorAll('input')[0].addEventListener('change', () => {
      const inputId = element.classList[1];
      console.log(inputId);
      const fileUploadValue = element.querySelectorAll('input')[0].value.split('\\').length > 0 ? element.querySelectorAll('input')[0].value.split('\\')[2] : '';
      element.querySelectorAll('div')[0].appendChild(createInputForForm(fileUploadValue, inputId));
    });
  });
}

function createInputForForm(value, id) {
  let input = document.getElementById(id);
  
  if (input === null) {
    input = document.createElement('input');
    input.id = id;
    input.setAttribute('style', 'height:100%;border:0');
    input.setAttribute("type", "text");
  }

  input.setAttribute("value", value);

  return input;
}

addSearchSubmit();
addSearchFilterSubmit();
addSearchReset();
showFilters();
