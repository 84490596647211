function addClassToTables () {
  const content = document.getElementById('jsContent');

  if (content) {
    const tables = content.getElementsByTagName('table');

    for (let i = 0; i < tables.length; ++i ) {
      tables[i].classList.add('table', 'table-striped');
    }
  }
}

addClassToTables();
