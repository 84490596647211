function closeMenu () {
  const btn = document.getElementById('jsToggleMenu'),
        collapseMenu = document.getElementById('jsNavbarCollapse'),
        submenuItems = document.querySelectorAll('.jsSubMenu');

  collapseMenu.classList.remove('collapse-active');
  setTimeout(() => {
    collapseMenu.classList.add('fade-out-collapse');
  }, 1);
  setTimeout(() => {
    collapseMenu.classList.remove('fade-out-collapse');
    collapseMenu.classList.add('d-none');
  }, 301);
  btn.classList.remove('is-open');
  btn.setAttribute('aria-expanded', 'false');

  for (let i = 0; i < submenuItems.length; i++) {
    const buttons = submenuItems[i].getElementsByClassName('navigation__link-btn'),
          lists = submenuItems[i].getElementsByClassName('navigation__sub-list');

    if (buttons[0] != undefined) {
      buttons[0].setAttribute('aria-expanded', 'false');
    }
    if (lists[0] !== undefined) {
      lists[0].classList.remove('submenu-active', 'h-auto');
    }
  }
}

function toggleMenu () {
  const btn = document.getElementById('jsToggleMenu'),
        collapseMenu = document.getElementById('jsNavbarCollapse');

  if (btn) {
    btn.addEventListener('click', () => {
      let attribute = btn.getAttribute('aria-expanded');

      if (attribute === 'false') {
        collapseMenu.classList.remove('d-none');
        setTimeout(() => {
          collapseMenu.classList.add('fade-in-collapse');
        }, 1);
        setTimeout(() => {
          collapseMenu.classList.add('collapse-active');
          collapseMenu.classList.remove('fade-in-collapse');
        }, 301);
        btn.classList.add('is-open');
        btn.setAttribute('aria-expanded', 'true');
      } else {
        closeMenu();
      }
    });
  }
}

function toggleSubMenu () {
  const items = document.querySelectorAll('.jsSubMenu');

  if (items) {
    for (let i = 0; i < items.length; i++) {
      const buttons = items[i].getElementsByClassName('navigation__link-btn');

      buttons[0].addEventListener('click', (e) => {
        let target = e.target,
            submenu = target.parentNode.nextElementSibling;

        if (target.getAttribute('aria-expanded') === 'false') {
          submenu.classList.add('scale-in-ver-top');
          setTimeout(() => {
            submenu.classList.add('h-auto');
          }, 100);
          setTimeout(() => {
            submenu.classList.add('submenu-active');
            submenu.classList.remove('scale-in-ver-top');
          }, 201);
          target.setAttribute('aria-expanded', 'true');
        } else {
          submenu.classList.add('scale-out-ver-top');
          setTimeout(() => {
            submenu.classList.remove('submenu-active');
          }, 1);
          setTimeout(() => {
            submenu.classList.remove('scale-out-ver-top');
            submenu.classList.remove('h-auto');
          }, 201);
          target.setAttribute('aria-expanded', 'false');
        }
      });
    }
  }
}

function clickOutsideMenu () {
  const collapseMenu = document.getElementById('jsNavbarCollapse'),
        btn = document.getElementById('jsToggleMenu');

  document.addEventListener('click', (e) => {
    let isClickInside = collapseMenu.contains(e.target),
        isMenuToggle = btn.contains(e.target);

    if (!isClickInside && !isMenuToggle) {
      closeMenu();
    }
  });
}

function addLanguageEvent () {
  const languageUrls = document.getElementsByClassName('js-redirect-by-language');

  for (let i = 0; i < languageUrls.length; i++) {
    const item = languageUrls[i];
    item.onclick = function() {
      window.location.href = this.getAttribute('data-url');
    }
  }
}

addLanguageEvent();
toggleMenu();
toggleSubMenu();
clickOutsideMenu();
